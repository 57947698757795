import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { useRewrite } from '~/lib/route-rewrite/utilities'
import { getCategories } from '~/lib/route-rewrite/categories'

export default defineNuxtPlugin(async (context) => {
  const router = context.app.router
  if (!router) return

  let categories = window.__NUXT__.routeCategories
  if (!categories) {
    categories = await getCategories(context)
  }

  const { replaceMatch } = useRewrite(categories)
  replaceMatch(router)
})

