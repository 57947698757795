import { Media } from './common'
import { Address, Location } from './geo'

export interface OpeningTime {
  day: number | Date
  openTime?: string
  closeTime?: string
}

export interface RegularTime extends OpeningTime {
  day: number
}

export interface HolidayTime extends OpeningTime {
  day: Date
}

export interface OpeningTimes {
  regularTimes: RegularTime[]
  extraOpeningDays?: string
  extraClosingDays?: string
}

export interface Manager {
  firstName: string
  lastName: string
  image?: string
}

export interface StoreNews {
  date: Date | string
  title: string
  description: string
}

export interface Store extends Address {
  id: number
  name: string
  code?: string
  type?: string
  url?: string
  title?: string
  summary?: string
  latiutude?:string
  longitude?:string
  address?:string
  customText1?:string
  customText2?:string
  image?: string
  gallery?: Media[]
  email?: string
  phoneNumber?: string

  location?: Location

  manager?: Manager
  openingTimes?: OpeningTimes

  onFleetId?: string
}

export interface PickupLocation extends Address {
  id: number
  name: string
  code?: string
  email?: string
  phoneNumber?: string
  location?: Location
  distance?: number
  carrier?: string
}

export interface StoreParams {
  distanceFromLatitude?: number
  distanceFromLongitude?: number
  storeTags?: Array<string> | string
  Culture?:string
  Source?:string
}

export enum OrderBy {
  Distance = 1,
  Alphabetic = 2,
}

export enum FilterOption {
  Opened = 'opened',
  AfterDusk = 'afterDusk',
  Sunday = 'sunday',
}

export enum StoreLocationTag {
  Default = 'Mijn afhaalvestiging',
  Other = 'Mijn vestiging voor afhalen',
}

export interface FilterConfig {
  [FilterOption.Opened]: boolean
  [FilterOption.AfterDusk]: boolean
  [FilterOption.Sunday]: boolean
}
