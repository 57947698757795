import { is } from 'ramda'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { Address } from '@/types/geo'
import { Store } from "~/types/store";

const capitalize = (str: string) => {
  if (!str) return str

  return str[0].toUpperCase() + (str.length > 1 ? str.substring(1) : null)
}

const streetAddr = (address: Address) => {
  return `${address.street} ${address.houseNumber}${
    address.houseNumberExtension ? ' ' + address.houseNumberExtension : ''
  }`
}

const postalAddr = (address: Address) => {
  return `${address.postalCode} ${address.city}`
}

const fullAddr = (store: Store) => {
  console.log(`${store.address}`,"full address")
  return `${store.address}`
  // return `${address.street} ${address.houseNumber}${
  //   address.houseNumberExtension ? ' ' + address.houseNumberExtension : ''
  // }, ${address.city}`
}

const fullDate = (date: Date | string) => {
  if (is(String, date)) {
    date = new Date(date)
  }
  return new Intl.DateTimeFormat('nl-NL', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date)
}

const weekDate = (date: Date | string) => {
  if (is(String, date)) {
    date = new Date(date)
  }

  return new Intl.DateTimeFormat('nl-NL', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date)
}

const formatDate = (date: Date, options?: any) => {
  if (is(String, date)) {
    date = new Date(date)
  }

  return new Intl.DateTimeFormat('nl-NL', options).format(date)
}

export const utils = {
  capitalize,
  streetAddr,
  postalAddr,
  fullAddr,
  weekDate,
  fullDate,
  formatDate,
}

export default defineNuxtPlugin((_, inject) => {
  inject('utils', utils)
})
