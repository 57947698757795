export default {
  projectName: 'binorm',
  customer: {
    contactCentric: false
  },
  product: {
    allAreVariants: false,
    enableAddToCartOnList: true,
    showSecondImageOnHover: false,
    useTopLevelImagesForVariants: false,
    useTopLevelMemoForVariants: true,
    variantType: {
      main: 'Variant',
      derivative: 'Product',
    },
    details: {
      commonConnectionTypes: ['Interesting'],
      variantConnectionTypes: ['Size', 'Color', 'Variant'],
    },
    viewableExcludeTypes: ['ProductVoucher'],
    discount: {
      matchPriceListId: true,
    },
  },
  store: {
    availableStoreExcludeIds: [1],
  },
  checkout: {
    payment: {
      provider: 'MultiSafepay2'
    }
  },
  axios: {
    urlsToCache: [
      // '/api/vm/u',
      // '/api/sp/config',
      '/api/aspos/common/config',
      '/api/aspos/webnodes/main/root'
    ]
  }
}
